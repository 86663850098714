.slider {
  height: 423px;
  margin: 0 auto;
  position: relative;
  width: 100%;

  .slides-container {
    position: relative;
    width: 100%;
  }

  .container-info {
    height: 100%;
    width: 100%;
  }

  .slide {
    height: 423px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease;
    width: 100%;

    &.active {
      height: 423px;
      opacity: 1;
      width: 100%;
    }

    img {
      height: 423px;
      min-width: 100%;
      width: 100%;
    }

    .slide-content {
      align-items: flex-start;
      bottom: 0;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;

      h2 {
        background: #d83298;
        color: #fff !important;
        font-size: 24px;
        font: 700 46px / 81px Trebuchet MS;
        height: 82px;
        margin-bottom: 10px;
        margin: 0;
        padding: 0 40px 0 20px;

        &:hover {
          background-color: #333333 !important;
          cursor: pointer;
        }
      }

      p {
        background-color: blue;
        display: block;
        font-size: 20px;
        line-height: 23px;
        height: 140px;
        margin: 0;
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
        width: 570px;

        &:hover {
          background-color: #333333 !important;
          cursor: pointer;
        }
      }
    }
  }

  .overlay:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  .prev,
  .next {
    align-items: center;
    background: #cecece80;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 66px;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    z-index: 99;

    img {
      width: 20px;
    }
  }

  .prev {
    left: 0;

    img {
      transform: rotate(180deg);
    }
  }

  .next {
    right: 0;
  }

  .dots {
    bottom: 10px;
    display: flex;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    span {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      cursor: pointer;
      height: 12px;
      margin: 0 5px;
      width: 12px;
    }

    span.active {
      background: white;
    }
  }
}
