@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.Register {
  width: 100%;

  .container-register {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .container-form {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 33%;

      .view-password {
        background-color: black;
        color: $white;
        border: none;
        margin-top: 5px;
        padding: 5px;
      }

      label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 5px;
        padding: 10px 0 0 0;
      }

      input {
        background-color: #fff;
        border-radius: 0;
        border: 1px solid #d4d4d4;
        box-shadow: none;
        box-sizing: border-box;
        color: #555;
        font-size: 14px;
        height: 34px;
        line-height: 18px;
        padding: 6px 12px;
        width: 100%;
      }
      
    }

    .forgot-password {
      background-color: transparent;
      border: none;
      text-align: left;
      padding: 0;
      margin: 10px 0;
      cursor: pointer;

      &:hover {
        color: #d83298;
      }
    }

    .container-submit {
      display: flex;
      width: 100%;
      gap: 10px;
      margin-top: 20px;
      
      .forgot-password {
        margin-left: 10px;
      }

      .submit-login {
        padding: 19px 20px;
        font: bold 15px 'Trebuchet MS';
        border-radius: 0;
        background-color: $black;
        color: white;
        border: none;
      }

    }
    
  }
}
