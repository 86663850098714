@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Account {
  display: flex;
  justify-content: center;
  padding: 0 30px 20px 30px;

  .account-container {
    width: 100%;
    max-width: $max-witdh-desktop;

    .breadcrumbs {
      width: 100%;

      .breadcrumb {
        padding: 8px 15px;
        box-sizing: border-box;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;

        .divider {
          margin: 0 7px;
          font-size: pxToRem(14px);
        }

        span {
          font-size: pxToRem(16px);
        }

        li {
          display: inline-block;

          a {
            color: $black;
            font-size: pxToRem(16px);
            text-decoration: none;

            &:hover {
              color: #d83298;
            }
          }
        }
      }
    }

    .checkout-account {
      display: flex;
      gap: 0 10px;
      justify-content: space-between;

      .left-account {
        width: 75%;

        .account-title {
          border-bottom: 3px solid #000000;
          color: #000000;
          font: bold 24px / 1em "Trebuchet MS";
          margin: 0 0 15px 0;
          padding: 26px 0 16px 0;
          text-transform: uppercase;
          text-align: left;
        }

        .container-account {
          .name {
            display: flex;
            justify-content: space-between;
            margin: 0 0 10px 0;
            padding: 15px 0 10px 0;
            border-bottom: 1px solid #e1e1e1;

            p {
              font-size: 20px;
              line-height: 30px;
              margin: 0;
              color: $black;
            }

            button {
              background-color: transparent;
              border: none;
              color: red;
              font-size: 20px;
              cursor: pointer;
            }
          }

          .email {
            text-align: left;
            font-size: 15px;
          }
        }
      }
    }
  }
}
