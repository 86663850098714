@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Showcase {
  display: flex;
  gap: 0 20px;
  width: 100%;
  justify-content: space-between;

  .banner {
    align-items: center;
    background: #009cea;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: max-content;
    margin: 30px 0;
    padding: 30px 10px;
    width: 25%;
    transition: background 0.6s ease;

    &:nth-child(2) {
      background: #7cbb00;
    }

    &:nth-child(3) {
      background: #ffbb00;
    }

    &:nth-child(4) {
      background: #d83298;
    }

    img {
      width: 150px;
    }

    h3 {
      color: $white;
      font: bold 33px / 1em "Trebuchet MS";
      margin: 0;
      padding: 11px 25px 10px 25px;
      text-transform: none;
    }

    p {
      color: $white;
      font: normal 17px / 20px "Trebuchet MS";
      margin: 0;
    }

    button {
      background: transparent;
      border: none;
      outline: unset;

      img {
        margin: 20px 10px 0;
        width: 35px;
      }
    }

    &:hover {
      background: #333333;
      -moz-transition: background 0.2s ease;
      -ms-transition: background 0.2s ease;
      -o-transition: background 0.2s ease;
      transition: background 0.2s ease;
    }
  }
}
