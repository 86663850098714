@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Checkout {
  display: flex;
  justify-content: center;
  padding: 0 30px 20px 30px;

  .container-checkout {
    width: 100%;
    max-width: $max-witdh-desktop;

    .breadcrumbs {
      width: 100%;

      .breadcrumb {
        padding: 8px 15px;
        box-sizing: border-box;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;

        .divider {
          margin: 0 7px;
          font-size: pxToRem(14px);
        }

        span {
          font-size: pxToRem(16px);
        }

        li {
          display: inline-block;

          a {
            color: $black;
            font-size: pxToRem(16px);
            text-decoration: none;

            &:hover {
              color: #d83298;
            }
          }
        }
      }
    }

    .checkout-info {
      display: flex;
      gap: 0 10px;
      justify-content: space-between;

      .left-checkout {
        width: 75%;

        .product-title {
          border-bottom: 3px solid #000000;
          color: #000000;
          font: bold 24px / 1em "Trebuchet MS";
          margin: 0 0 15px 0;
          padding: 26px 0 16px 0;
          text-transform: uppercase;
          text-align: left;
        }

        .products {
          display: flex;
          flex-wrap: wrap;
          // gap: 20px 10px;
          width: 100%;
          justify-content: space-between;

          .product {
            display: flex;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #e1e1e1;

            .container-image {
              width: 25%;

              img {
                width: 100%;
              }
            }

            .infos-product {
              width: 75%;

              .name-product {
                width: 100%;

                .item-title {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 20px;
                  text-align: left;
                  line-height: 30px;

                  .exit {
                    cursor: pointer;
                  }
                }

                .item-vendor {
                  padding: 10px 0;
                  border-bottom: 1px solid #e1e1e1;
                  font-size: 16px;
                  color: #888;
                  width: 100%;
                  text-align: left;
                  margin: 0;
                }
              }

              .price-product {
                padding: 10px 0 0 0;
                display: flex;
                justify-content: space-between;
                width: 100%;

                .price-item {
                  width: 140px;

                  span {
                    font: bold 20px / 37px "Trebuchet MS";
                    color: #009cea;
                  }

                  &:first-child {
                    text-align: left;
                  }
                }

                .contain-product {
                  align-items: center;
                  display: flex;
                  justify-content: space-between;
                  width: 90px;

                  p {
                    font-family: $primary_font_demi;
                    font-size: 16px;
                    margin: 0 10px;
                    text-transform: uppercase;
                  }

                  button {
                    background: none;
                    border: none;
                    color: inherit;
                    cursor: pointer;
                    font: inherit;
                    outline: inherit;
                    padding: 0;

                    img {
                      height: 19px;
                      width: 19px;
                    }
                  }
                }
              }
            }
          }

          .subtotal {
            // background-color: red;
            width: 100%;

            .info-total {
              display: flex;
              border-bottom: 1px solid #e1e1e1;
              padding: 15px 0;
              box-sizing: border-box;
              justify-content: space-between;

              p {
                margin: 0;
                font: bold 20px "Trebuchet MS";
                color: #151515;
              }

              span {
                font: bold 20px "Trebuchet MS";
                color: #009cea;
              }
            }

            .finish-total {
              width: 100%;
              padding: 15px 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;

              button {
                padding: 19px 20px;
                font: bold 15px "Trebuchet MS";
                border-radius: 0;
                background-color: $black;
                border: none;
                color: $white;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
