@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Navbar {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  .container-header {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 180px;
    padding: 0;
    position: relative;
    width: 100%;

    .header {
      max-width: $max-witdh-desktop;
      padding: 0 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .site-title {
        float: left;
        font: bold 80px / 1em "Trebuchet MS";
        letter-spacing: -3px;
        margin: 0;

        a {
          color: $black;
          text-decoration: none;
        }
      }

      .container-buttons {
        display: flex;
        align-items: center;
        gap: 3px;
        .user-button {
          width: 73px;
          height: 60px;
          background-color: #ffbb00;
          border: none;
          cursor: pointer;

          img {
            width: 55%;
          }

          &:hover {
            background: #333333;

            img {
              width: 45%;
            }
          }
        }

        .cart-button {
          gap: 10px;
          height: 60px;
          min-width: 271px;
          padding: 0 13px 0 15px;
          background-color: #009cea;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background: #333333;
          }

          div {
            display: flex;
            align-items: center;
            gap: 20px;

            p {
              color: white;
              font: normal 15px "Trebuchet MS";

              strong {
                font-size: 19px;
              }
            }
          }

          img {
            width: 33px;
          }

          // &:hover {
          //   background: #333333;

          //   img {
          //     width: 45%;
          //   }
          // }
        }
      }
    }
  }

  .navigation {
    background-color: $black;
    box-sizing: border-box;
    display: flex;
    height: 70px;
    justify-content: center;
    list-style: none;
    padding: 0 30px;
    width: 100%;

    nav {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      max-width: $max-witdh-desktop;
      width: 100%;

      button {
        background-color: $black;
        border-width: 0 1px;
        border: 1px solid $white;
        border-bottom: unset;
        color: #ffffff;
        cursor: pointer;
        font-size: pxToRem(22px);
        font: bold 19px / 70px "Trebuchet MS";
        height: 100%;
        padding: 0 20px;
        text-transform: uppercase;

        &:hover {
          background: #333333;
        }
      }
    }
  }
}
