@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;

  .header-footer {
    display: flex;
    max-width: $max-witdh-desktop;
    width: 100%;
    padding: 61px 0 53px 0;

    .block {
      width: 25%;
      padding-right: 25px;

      &:last-child {
        padding: 0;
      }

      h3 {
        margin: 0;
        padding: 0 0 16px 0;
        border-bottom: 3px solid #000000;
        font: bold 19px / 1em "Trebuchet MS";
        color: #000000;
        text-align: left;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        padding: 13px 0 0 0;
        font: normal 15px / 27px "Trebuchet MS";
        color: #9e9d9d;
        text-align: left;

        &.tel {
          margin: 0;
          padding: 14px 0 0 0;
          font: bold 18px / 1em 'Trebuchet MS';
          color: #9e9d9d;
        }
      }

      ul {
        margin: 0;
        padding: 0px;
        padding: 9px 0 0 0;

        li {
          padding: 8px 0 0 0;
          line-height: 1em;
          list-style: none;
          text-align: left;

          a {
            font: normal 15px "Trebuchet MS";
            color: #9e9d9d;

            &:hover {
              color: #000000;
            }
          }
        }
      }
    }
  }

  footer {
    border-top: 3px solid #000000;
    color: #9e9d9d;
    display: flex;
    font: normal 15px / 1em 'Trebuchet MS';
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-witdh-desktop;
    padding: 17px 0 0 0;
    width: 100%;
  }
}
