@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.NewProducts {
  padding-bottom: 40px;


  .page-title {
    border-bottom: 3px solid #000000;
    color: #000000;
    font: bold 24px / 1em 'Trebuchet MS';
    margin: 0 0 15px 0;
    padding: 26px 0 16px 0;
    text-transform: uppercase;
    text-align: left;
  }

  .container-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
    // gap: 1px;
  }
}