.Home {
  height: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;

  .container {
    width: 100%;
    max-width: 1216px;
  }
}