@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ProductCollections {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 230px;

  .product-image-wrap {
    padding: 0 20px;

    a {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      display: block;
      padding: 15px;

      img {
        height: 180px;
        min-width: 180px;
        width: 100%;
      }
    }
  }
  .product_title {
    min-height: 41px;
    max-height: 40px;
    margin: 20px 0;
    text-align: center;
    line-height: 20px;
    display: block;
    font: normal 16px "Trebuchet MS";

    &:hover {
      color: #d83298;
    }
  }

  .infos-product {
    align-items: center;
    background-color: #009cea;
    display: flex;
    height: 60px;
    justify-content: space-between;
    width: 100%;

    span {
      display: inline-block;
      padding: 0 0 0 15px;
      font: bold 23px / 60px "Trebuchet MS";
      color: #ffffff;
    }

    div {
      height: 100%;

      button {
        background-color: transparent;
        border: unset;
        height: 100%;
        width: 60px;
        background: #7cbb00;
        cursor: pointer;
        transition: background 0.6s ease;

        &.active {
          background-color: #fe5f23;
        }

        &:nth-child(2) {
          background: #ffbb00;

          img {
            width: 30px;
            height: 50%;
          }

          &:hover {
            background: #d83298;
          }
        }

        img {
          height: 50%;
          width: 20px;
        }

        &:hover {
          background: #fe5f23;
          -moz-transition: background 0.2s ease;
          -ms-transition: background 0.2s ease;
          -o-transition: background 0.2s ease;
          transition: background 0.2s ease;
        }
      }
    }
  }
}