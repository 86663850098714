@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.RightComponent {
  width: 23%;

  .product-title {
    border-bottom: 3px solid #000000;
    color: #000000;
    font: bold 24px / 1em "Trebuchet MS";
    margin: 0 0 15px 0;
    padding: 26px 0 16px 0;
    text-transform: uppercase;
    text-align: left;
  }

  .widget_content {
    .list {
      margin: 0;
      padding: 0px;

      li {
        list-style: none;
        line-height: 19px;
        padding: 5px 0;
        text-align: left;
        border-bottom: 1px solid #e1e1e1;

        a {
          font: normal 18px "Trebuchet MS";
          text-transform: uppercase;
          color: #000000;
        }
      }
    }
  }

  .container-products-sellers {
    gap: 20px;
    display: grid;

    .product-seller {
      .container-top-product {
        display: flex;

        .product-image {
          align-items: center;
          border: 1px solid #e1e1e1;
          display: flex;
          height: 110px;
          justify-content: center;
          margin: 0 15px 15px 0;
          min-width: 110px;

          img {
            width: 88px;
            height: 88px;
          }
        }

        a {
          margin: 0;
          text-align: left;
          font: normal 15px "Trebuchet MS";

          &:hover {
            color: #d83298;
          }
        }
      }

      .infos-product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        background-color: #009cea;

        span {
          display: inline-block;
          padding: 0 0 0 15px;
          font: bold 20px / 40px "Trebuchet MS";
          color: #ffffff;
        }

        div {
          height: 100%;

          button {
            background-color: transparent;
            border: unset;
            height: 100%;
            width: 50px;
            background: #7cbb00;
            cursor: pointer;
            transition: background 0.6s ease;

            &.active {
              background: #fe5f23;
            }

            &:nth-child(2) {
              background: #ffbb00;

              img {
                width: 10px;
                height: 50%;
              }

              &:hover {
                background: #d83298;
              }
            }

            img {
              height: 50%;
              width: 20px;
            }

            &:hover {
              background: #fe5f23;
              -moz-transition: background 0.2s ease;
              -ms-transition: background 0.2s ease;
              -o-transition: background 0.2s ease;
              transition: background 0.2s ease;
            }
          }
        }
      }
    }
  }
}