@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Product {
  width: 24%;
  min-width: 270px;
  // background-color: red;

  .product-image {
    margin: 0 20px;
    padding: 15px;
    border: 1px solid #e1e1e1;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      height: 225px;
      width: 190px;
    }
  }

  .description-product {
    font: normal 16px "Trebuchet MS";
    height: 64px;
    line-height: 20px;
    margin: 20px 0 0 0;
    min-height: 41px;
    text-align: center;
  }

  .infos-product {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: #009cea;

    span {
      display: inline-block;
      padding: 0 0 0 15px;
      font: bold 23px / 60px "Trebuchet MS";
      color: #ffffff;
    }

    div {
      height: 100%;

      button {
        background-color: transparent;
        border: unset;
        height: 100%;
        width: 61px;
        background: #7cbb00;
        cursor: pointer;
        transition: background 0.6s ease;

        &.active {
          background-color: #fe5f23;
        }

        &:nth-child(2) {
          background: #ffbb00;

          img {
            width: 30px;
            height: 50%;
          }

          &:hover {
            background: #d83298;
          }
        }

        img {
          height: 50%;
          width: 40px;
        }

        &:hover {
          background: #fe5f23;
          -moz-transition: background 0.2s ease;
          -ms-transition: background 0.2s ease;
          -o-transition: background 0.2s ease;
          transition: background 0.2s ease;
        }
      }
    }
  }
}
