@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ProdutcDetails {
  display: flex;
  justify-content: center;
  padding: 0 30px 20px 30px;

  .container-product-details {
    width: 100%;
    max-width: $max-witdh-desktop;
  }
  .breadcrumbs {
    width: 100%;

    .breadcrumb {
      padding: 8px 15px;
      box-sizing: border-box;
      margin-bottom: 20px;
      background-color: #f5f5f5;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;

      .divider {
        margin: 0 7px;
        font-size: pxToRem(14px);
      }

      li {
        display: inline-block;

        a {
          color: $black;
          font-size: pxToRem(16px);
          text-decoration: none;
        }
      }
    }
  }

  .containers-product {
    display: flex;
    gap: 0 10px;
    justify-content: space-between;

    .center-product {
      width: 75%;

      .product-title {
        border-bottom: 3px solid #000000;
        color: #000000;
        font: bold 24px / 1em "Trebuchet MS";
        margin: 0 0 15px 0;
        padding: 26px 0 16px 0;
        text-transform: uppercase;
        text-align: left;
      }

      .details {
        display: flex;
        gap: 0 20px;
        padding: 10px 0 0 0;
        align-items: flex-start;

        .carousel-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          // max-width: 345px;
          width: 477px;

          .carousel {
            position: relative;
            width: 80%;
            width: 100%;
            max-width: 345px;
            overflow: hidden;

            .container-buttons {
              position: absolute;
              height: 30px;
              width: 100%;
              top: 50%;
              margin-top: -16px;
              display: flex;
              justify-content: space-between;

              button {
                height: 40px;
                display: flex;
                align-items: center;
                background: #cecece80;
                justify-content: center;
                border: unset;
                cursor: pointer;

                &:hover {
                  background: rgba(0, 0, 0, 0.7);
                }

                &:nth-child(1) {
                  img {
                    transform: rotate(180deg);
                  }
                }

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            img {
              width: 100%;
              height: 300px;
            }
          }

          .thumbnails {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            width: 100%;
            max-width: 345px;

            margin: 15px 0 0 0;
            padding: 15px 0 0 0;
            border-top: 1px solid #e1e1e1;

            img {
              width: 30%;
              height: auto;
              cursor: pointer;
              opacity: 0.6;
              transition: opacity 0.3s ease;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        .product-description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .price {
            color: #009cea;
            padding: 0;
            margin: 0;
            font: bold 23px "Trebuchet MS";
          }

          .purchase {
            vertical-align: top;
            display: flex;
            align-items: center;
            padding: 15px 0 0 0;

            // .btn {
            //   padding: 19px 20px;
            //   font: bold 15px "Trebuchet MS";
            // }

            .contain-product {
              align-items: center;
              display: flex;
              justify-content: space-between;

              p {
                font-family: $primary_font_demi;
                font-size: 16px;
                margin: 0 10px;
                text-transform: uppercase;
              }

              button {
                background: none;
                border: none;
                color: inherit;
                cursor: pointer;
                font: inherit;
                outline: inherit;
                padding: 0;

                img {
                  height: 19px;
                  width: 19px;
                }
              }
            }

            .add-cart {
              background-color: $black;
              border: unset;
              color: white;
              cursor: pointer;
              font: bold 15px 'Trebuchet MS';
              height: 50px;
              margin-left: 25px;
              padding: 10px;
            }
          }

          .product_details {
            margin: 15px 0 0 0;
            padding: 10px 0 15px 0;
            border: 1px solid #e1e1e1;
            border-width: 1px 0;
            width: 100%;

            .product_type {
              text-align: left;
              padding: 5px 0 0 0;
            }
          }

          .product_description {
            h3 {
              padding: 15px 0 10px 0;
              font-size: 18px;
              text-transform: none;
              text-align: left;
              line-height: 30px;
              font-weight: 300;
            }

            p {
              margin: 0;
              text-align: left;
              font-size: 15px;
              line-height: 18px;
            }
          }
        }
      }

      .other-products {
        .container-other-products {
          display: flex;
          gap: 0 20px;

          .product-other {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 33.33%;

            .product-image-wrap {
              padding: 0 20px;

              a {
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                border: 1px solid #e1e1e1;
                box-sizing: border-box;
                display: block;
                padding: 15px;

                img {
                  width: 100%;
                }
              }
            }
            .product_title {
              min-height: 41px;
              margin: 20px 0 0 0;
              text-align: center;
              line-height: 20px;
              display: block;
              font: normal 16px "Trebuchet MS";

              &:hover {
                color: #d83298;
              }
            }

            .infos-product {
              align-items: center;
              background-color: #009cea;
              display: flex;
              height: 60px;
              justify-content: space-between;
              width: 100%;

              span {
                display: inline-block;
                padding: 0 0 0 15px;
                font: bold 23px / 60px "Trebuchet MS";
                color: #ffffff;
              }

              div {
                height: 100%;

                button {
                  background-color: transparent;
                  border: unset;
                  height: 100%;
                  width: 60px;
                  background: #7cbb00;
                  cursor: pointer;
                  transition: background 0.6s ease;

                  &:nth-child(1) {
                    background: #ffbb00;

                    img {
                      width: 30px;
                      height: 50%;
                    }

                    &:hover {
                      background: #d83298;
                    }
                  }

                  img {
                    height: 50%;
                    width: 20px;
                  }

                  &:hover {
                    background: #fe5f23;
                    -moz-transition: background 0.2s ease;
                    -ms-transition: background 0.2s ease;
                    -o-transition: background 0.2s ease;
                    transition: background 0.2s ease;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
